import React from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";

import "./RightHeaderButton.css";

const RightHeaderButton = ({ link }) => {
  return (
    <div className="rightHeaderButton">
      <Router />
      {link !== "settings" ? (
        <NavLink to={link ? `/${link}` : "/mon-profil"}>
          {link === "parametres" ? (
            <div className="header__right-button">
              <span className="header__label">Paramètres</span>
              <img src={window.location.origin + "/images/assets/settings.svg"} alt=""></img>
            </div>
          ) : (
            <div className="header__right-button">
              <span className="header__label">Mon compte</span>
              <img className="rightHeaderButton__avatar" src={window.userData.acf.profil_users.url} alt=""></img>
            </div>
          )}
        </NavLink>
      ) : (
        false
      )}
    </div>
  );
};

export default RightHeaderButton;
