import React, { Component } from "react";
import "./Login.css";
import PropTypes from "prop-types";

class PasswordInput extends Component {
  static defaultProps = {
    name: "password",
    placeholder: "Saisissez votre mot de passe",
    required: true,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ value });
  };

  render() {
    const { name, placeholder, required } = this.props;
    return (
      <div>
        <label name={name} className="label bold-text">
          Mot de passe
        </label>
        <input
          id="password"
          className="input"
          name={name}
          onChange={this.handleChange}
          placeholder={placeholder}
          required={required}
          type="password"
          value={this.state.value}
        />
      </div>
    );
  }
}

export default PasswordInput;
