import React, { useState, useEffect } from "react";
import Event from "../../Event";
import Loader from "../../Loader";
import axios from "axios";

function Events(companyId) {
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (hasMore) {
      setLoading(true);
      let cancel;
      axios
        .get(process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_mag/", {
          params: {
            _embed: 1,
            author: companyId.companyId,
            arch_cat_mag: 14,
            per_page: 2,
            page: pageNumber,
          },
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
        .then(function (response) {
          if (Number(response.headers["x-wp-totalpages"]) === pageNumber) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setData([...data, ...response.data]);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setError(true);
        })
        .then(function () {
          // always executed
        });
      return () => cancel();
    }
  }, [pageNumber]);

  // User bottom page
  useEffect(() => {
    function handleBottom() {
      // si l'utilisateur atteind le bas de la page, on charge la page suivante
      if (document.getElementById("root").getBoundingClientRect().bottom <= window.innerHeight + 50) {
        // on ajoute 50 pour lancer le chargement légèrement avant qu'on atteingne le bas de la page
        if (!loading && hasMore) {
          setPageNumber((v) => v + 1);
        }
      }
    }
    document.addEventListener("scroll", handleBottom);

    return function () {
      document.removeEventListener("scroll", handleBottom);
    };
  }, [loading, hasMore]);

  return (
    <div>
      {data ? (
        <div>
          {data.map((event, index) => {
            if (event.arch_cat_mag[0] === 14 && event.acf.publier_app) {
              return (
                <Event
                  avatar={event._embedded.author[0].acf.profil_users.url}
                  idEvent={event.id}
                  date={event.acf.date_event}
                  hour={event.acf.heure_event}
                  localisation={event.acf.lieu_event}
                  banner={event._embedded["wp:featuredmedia"][0].media_details.sizes["app-size"].source_url}
                  bannerFull={event._embedded["wp:featuredmedia"][0].media_details.sizes["full"].source_url}
                  text={event.acf.texte_app}
                  userId={event.author}
                  user={event._embedded.author[0].name}
                  key={index}
                  datePost={event.date}
                ></Event>
              );
            }
          })}
          {data.length === 0 && !loading && <p className="center-text">Aucun événement posté</p>}
        </div>
      ) : (
        error && <div className="job-page__content">Une erreur s'est produite</div>
      )}
      {loading && <Loader></Loader>}
    </div>
  );
}

export default Events;
