import React, { useEffect, useState } from "react";
import "./Search.css";
import algoliasearch from "algoliasearch/lite";
import ScrollToTop from "../../../utils/scrollTotop";
import { useHistory } from "react-router-dom";
import { InstantSearch, SearchBox } from "react-instantsearch-dom";
import InfiniteHits from "./InfiniteHits";

const searchClient = algoliasearch(process.env.REACT_APP_API_ALGOLIA_ID, process.env.REACT_APP_API_ALGOLIA_KEY);

function Search({ displayMenu, searchValue }) {
  ScrollToTop();

  const history = useHistory();
  const [searchPage, setSearchPage] = useState(false);

  useEffect(() => {
    if (displayMenu !== true) {
      if (document.getElementById("menu")) {
        document.getElementById("menu").style.display = "none";
        setSearchPage(true);
      }
    } else if (document.getElementById("menu")) {
      document.getElementById("menu").style.display = "flex";
      setSearchPage(false);
    }

    return function () {
      if (document.getElementById("menu")) {
        document.getElementById("menu").style.display = "flex";
        setSearchPage(false);
      }
    };
  }, []);

  return (
    <div>
      <InstantSearch indexName="wp_searchable_posts" searchClient={searchClient}>
        <div className="search__header">
          <div className="search__searchbar">
            <div className="search__header-icon">
              {searchPage ? (
                <img
                  className="clickable"
                  src={window.location.origin + "/images/assets/Icons-ReturnBack.svg"}
                  alt=""
                  onClick={() => {
                    history.goBack();
                  }}
                ></img>
              ) : (
                <img src={window.location.origin + "/images/assets/icons-search.svg"} alt=""></img>
              )}
            </div>
            {displayMenu && (
              <SearchBox
                defaultRefinement={searchValue}
                translations={{
                  placeholder: "Rechercher par nom, activité, entreprise…",
                }}
              />
            )}
            {!displayMenu && (
              <SearchBox
                autoFocus
                translations={{
                  placeholder: "Rechercher par nom, activité, entreprise…",
                }}
              />
            )}
          </div>
        </div>
        <div className="search__container">
          <div className="search__wrapper">
            <InfiniteHits />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

export default Search;
