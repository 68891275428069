import React, { Component } from "react";
import "./Settings.css";
import PropTypes from "prop-types";

class FirstNameInput extends Component {
  static defaultProps = {
    name: "firstName",
    placeholder: "Antoine",
    required: false,
    value: "Antoine",
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ value });
  };

  render() {
    const { name, required } = this.props;
    return (
      <div className="input-control">
        <label name={name} className="label bold-text">
          Prénom
        </label>
        <input
          className="input"
          name={name}
          onChange={this.handleChange}
          placeholder={window.userData.name.split(" ")[0]}
          required={required}
          type="text"
          value={this.state.value}
        />
      </div>
    );
  }
}

export default FirstNameInput;
