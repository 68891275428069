import React from "react";
import { BrowserRouter as Router, NavLink, useHistory } from "react-router-dom";
import "./LeftHeaderButton.css";

function LeftHeaderButton({ linkLeftButton }) {
  const history = useHistory();
  return (
    <div className="leftHeaderButton">
      <Router />
      {
        // if button is search
        linkLeftButton === "search" ? (
          // then display search button
          <NavLink to="/rechercher">
            <div className="header__search-button">
              <img src={window.location.origin + "/images/assets/icons-search.svg"} alt=""></img>
              <span className="header__label">Rechercher</span>
            </div>
          </NavLink>
        ) : (
          //else display back button
          <img
            className="clickable"
            src={window.location.origin + "/images/assets/Icons-ReturnBack.svg"}
            alt=""
            onClick={() => {
              history.goBack();
            }}
          ></img>
        )
      }
    </div>
  );
}

export default LeftHeaderButton;
