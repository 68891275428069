import React from "react";
import { Get } from "react-axios";
import Loader from "../../Loader";
import Contact from "../phonebook/Contact";

function Employees(companyId) {
  return (
    <Get url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/hierarchical_users"}>
      {(error, response, isLoading, makeRequest, axios) => {
        if (error) {
          return (
            <div className="job-page__content">
              Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
            </div>
          );
        } else if (isLoading) {
          return (
            <div className="company-page__loader">
              <Loader></Loader>
            </div>
          );
        } else if (response !== null && response.data[companyId.companyId].salaries) {
          return (
            <div>
              {Object.values(response.data[companyId.companyId].salaries).map((employee, index) => {
                return (
                  <Contact
                    id={employee.id}
                    key={index}
                    name={employee.displayname}
                    avatar={employee.picture}
                    poste={employee.poste}
                  ></Contact>
                );
              })}
            </div>
          );
        }
        return <p className="center-text">Aucun employé trouvé</p>;
      }}
    </Get>
  );
}

export default Employees;
