import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "../../Header";
import "./Settings.css";
import Cookies from "js-cookie";
import "react-image-crop/dist/ReactCrop.css";
import FormSetting from "./FormSetting";

const disconnection = () => {
  Cookies.remove("logged");
  window.location.reload();
};

function Settings(props) {
  return (
    <div>
      <Header pageTitle="Paramètres" linkRightButton="settings"></Header>
      <div className="settings">
        <div className="settings__wrapper">
          <div className="settings__container">
            <div className="settings__title bold-text">Mon profil</div>

            <FormSetting></FormSetting>

            <div className="settings__credits">
              <div className="settings__title bold-text">Crédits</div>
              <div className="settings__links">
                <a href={
                    "mailto:info@archparc.fr?subject=J'ai besoin d'aide&body=Bonjour, j'ai besoin d'aide.<br>" +
                    "id :" +
                    window.userData.id +
                    "<br>Nom : " +
                    window.userData.name
                  } 
                    className="settings__link" to="/bienvenue">
                  Aide et assistance
                </a>
                <a className="settings__link" target="_blank" href="https://www.archparc.fr/mentions-legales/">
                  Mentions légales
                </a>
                <a className="settings__link" target="_blank" href="https://www.archparc.fr/politique-de-confidentialite/">
                  Politique de confidentialité
                </a>
                <a
                  href={
                    "mailto:info@archparc.fr?subject=Supprimer mon compte&body=Bonjour, Je souhaiterais que l'on supprime mon compte de l'application :\n" +
                    "id :" +
                    window.userData.id +
                    "Nom : " +
                    window.userData.name
                  }
                  className="primary-text settings__link"
                >
                  <svg width="15" height="15" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.5 0C4.239 0 3.97 0.0925001 3.781 0.2815C3.5925 0.47 3.5 0.739 3.5 1V1.5H0.5V2.5H1V10.5C1 11.3225 1.6775 12 2.5 12H8.5C9.3225 12 10 11.3225 10 10.5V2.5H10.5V1.5H7.5V1C7.5 0.739 7.4075 0.47 7.2185 0.281C7.03 0.0929999 6.7615 0 6.5 0H4.5ZM4.5 1H6.5V1.5H4.5V1ZM2 2.5H9V10.5C9 10.7775 8.7775 11 8.5 11H2.5C2.2225 11 2 10.7775 2 10.5V2.5ZM3 4V9.5H4V4H3ZM5 4V9.5H6V4H5ZM7 4V9.5H8V4H7Z"
                      fill="#E96F48"
                    />
                  </svg>
                  <span className="ml-2">Supprimer mon compte</span>
                </a>
              </div>
              <NavLink className="link-w" to="/">
                <div className="btn settings__delete-account-btn" onClick={disconnection}>
                  Déconnexion
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
