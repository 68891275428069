import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Virtual } from "swiper";
import "swiper/swiper-bundle.css";
import "./OnBoarding.css";

SwiperCore.use([Pagination, Navigation, Virtual]);

function OnBoarding(props) {
  const [data, setData] = useState(null);
  const [numberOfBoard, setNumberOfBoard] = useState(0);
  const [lastSlide, setLastSlide] = useState(false);

  useEffect(() => {
    // Remove Menu
    if (document.getElementById("menu")) {
      document.getElementById("menu").style.display = "none";
    }

    // Get DATA
    axios({
      medthod: "GET",
      url: process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_boarding",
    })
      .then((response) => {
        let boards = [];
        for (let index = 0; index < response.data.length; index++) {
          boards.push({
            title: response.data[index].acf.titre_board,
            text: response.data[index].acf.texte_board,
            image: response.data[index].acf.image_board.url,
          });
        }
        setData(boards);
        setNumberOfBoard(response.data.length);
      })
      .catch((e) => {});
  }, [numberOfBoard]);

  return (
    <div className="on-boarding">
      <NavLink className="on-boarding__ignore" to="/" onClick={() => (document.getElementById("menu").style.display = "flex")}>
        <img className="on-boarding__ignore-chevron" src={window.location.origin + "/images/assets/chevron.svg"} alt="" />
        Passer
      </NavLink>
      <div className="on-boarding__ignore"></div>
      {numberOfBoard > 0 ? (
        <div className="on-boarding-container">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: ".on-boarding__next",
            }}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => {}}
            onSlideChange={(swiper) => {
              if (swiper.activeIndex === numberOfBoard - 1) {
                setLastSlide(true);
              } else {
                setLastSlide(false);
              }
            }}
          >
            {data.map((board, index) => {
              return (
                <SwiperSlide key={index} virtualIndex={index}>
                  <div className="on-boarding__image">
                    <img className="" src={board.image} alt="" />
                  </div>
                  <div className="on-boarding__title">{board.title}</div>
                  <div className="on-boarding__content">{board.text}</div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {lastSlide ? (
            <NavLink to="/">
              <div className="on-boarding__next bold-text">Terminer</div>
            </NavLink>
          ) : (
            <div className="on-boarding__next bold-text">Suivant</div>
          )}
        </div>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
}

export default OnBoarding;
