import React from "react";
import "./Announcement.css";
import CardHeading from "./CardHeading";
import ContactButton from "./ContactButton";
import Moment from "react-moment";
import "moment/locale/fr";
import Swal from "sweetalert2";
import axios from "axios";
import "animate.css";

function Announcement({ user, content, avatar, date, avatarCompany, userId, mail, id, image, imageFull }) {
  function deleteAnnouncement() {
    new Swal({
      icon: "question",
      title: "Supprimer",
      text: "Souhaitez-vous supprimer cette annonce ?",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Supprimer",
      confirmButtonColor: "#E96F48",
      preConfirm: () => {
        return axios
          .delete(process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_annonces/" + id, {
            headers: {
              Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=",
            },
          })
          .then(function (response) {
            document.getElementById(id).style.display = "none";
          })
          .catch(function (error) {
            Swal.fire("Une erreur s'est produite", "Impossible de supprimer cette annonce", "error");
          })
          .then(function () {
            // always executed
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  if (content) {
    content = content.replace("<p>", "").replace("</p>", "");
  }

  function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
  }

  return (
    <div id={id} className="announcement animate__animated animate__fadeInUp">
      <div className="annoucement__heading">
        <CardHeading
          user={user}
          avatar={avatar}
          text=" a publié une petite annonce."
          date={
            <Moment locale="fr" fromNow>
              {date}
            </Moment>
          }
          companyAvatarOver={avatarCompany}
          userId={userId}
        ></CardHeading>
        {window.userData.id === userId ? (
          <div className="announcement__more-options" onClick={deleteAnnouncement}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4 13C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13Z"
                stroke="#506076"
              />
              <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                stroke="#506076"
              />
              <path
                d="M20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11C19.4477 11 19 11.4477 19 12C19 12.5523 19.4477 13 20 13Z"
                stroke="#506076"
              />
            </svg>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="announcement__content">
        <p>{content && removeTags(content).replace("&lsquo;", "'").replace("&rsquo;", "'").replace("&#038;", "&")}</p>
      </div>
      {image && (
        <div className="announcement__image">
          <picture>
            <source media="(min-width: 480px)" srcSet={imageFull}></source>
            <img src={image} alt="news"></img>
          </picture>
        </div>
      )}

      <ContactButton mail={mail} lightness></ContactButton>
    </div>
  );
}

export default Announcement;
