import React from "react";
import Header from "../../Header";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./JobPage.css";
import { Get } from "react-axios";
import Loader from "../../Loader";
import Moment from "react-moment";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function JobPage(props) {
  ScrollToTop();
  const jobId = window.location.pathname.replace("/offre-d-emploi/", "");
  return (
    <div className="job-page">
      <Header pageTitle="Offre d'emploi"></Header>
      <Get url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_offres/" + jobId} params={{ _embed: 1 }}>
        {(error, response, isLoading, makeRequest, axios) => {
          if (error) {
            return (
              <div className="job-page__content">
                Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
              </div>
            );
          } else if (isLoading) {
            return (
              <div className="job-page__content">
                <Loader></Loader>
              </div>
            );
          } else if (response !== null) {
            return (
              <div>
                <div className="job-page__banner img-cover">
                  <picture>
                    <source
                      media="(min-width: 480px)"
                      srcSet={
                        response.data._embedded["wp:featuredmedia"] &&
                        response.data._embedded["wp:featuredmedia"][0].media_details.sizes["full"].source_url
                      }
                    ></source>
                    <img
                      src={
                        response.data._embedded["wp:featuredmedia"] &&
                        response.data._embedded["wp:featuredmedia"][0].media_details.sizes["app-size"].source_url
                      }
                      alt="news"
                    ></img>
                  </picture>
                </div>
                <div className="job-page__avatar-over-banner-wrapper">
                  <div className="job-page__avatar-over-banner">
                    <img src={response.data._embedded.author[0].acf.profil_users.url} alt="" />
                  </div>
                </div>
                <div className="job-page__content-wrapper">
                  <div className="job-page__content">
                    <div className="job-page__post-date">
                      <p className="small-text small-text--blue">
                        Publié le{" "}
                        <Moment locale="fr" format="D MMMM YYYY">
                          {response.data.data}
                        </Moment>
                        , par{" "}
                        <NavLink to={"/profil/" + response.data._embedded.author[0].id}>
                          <span className="link bold-text secondary-text">{response.data._embedded.author[0].name}</span>
                        </NavLink>
                      </p>
                    </div>
                    <div className="job-page__title">
                      <h1>{response.data.title.rendered}</h1>
                    </div>
                    <div className="job-page__job-infos">
                      <div className="subtitle-text subtitle-text--orange">Détails de l'offre</div>
                      <div className="job-page__info">
                        <div className="job-page__icon-info">
                          <img src={window.location.origin + "/images/assets/clock-icon.svg"} alt="" />
                        </div>
                        <div className="secondary-text job-page__text-info">{response.data.acf.contrat_offre}</div>
                      </div>
                      <div className="job-page__info">
                        <div className="job-page__icon-info">
                          <img src={window.location.origin + "/images/assets/dollars-icon.svg"} alt="" />
                        </div>
                        <div className="secondary-text job-page__text-info">{response.data.acf.salaire_offre}</div>
                      </div>
                      <div className="job-page__info">
                        <div className="job-page__icon-info">
                          <img src={window.location.origin + "/images/assets/job-icon.svg"} alt="" />
                        </div>
                        <div className="secondary-text job-page__text-info">{response.data.acf.experience_offre}</div>
                      </div>
                    </div>
                    <div className="subtitle-text">Description</div>
                    <div className="job-page__text" dangerouslySetInnerHTML={{ __html: response.data.acf.description_offre }}></div>
                    <div className="subtitle-text">Le poste</div>
                    <div className="job-page__text" dangerouslySetInnerHTML={{ __html: response.data.acf.poste_offre }}></div>
                    <a href={"mailto:" + response.data._embedded.author[0].user_email}>
                      <div className="btn">Postuler</div>
                    </a>
                  </div>
                </div>
              </div>
            );
          }
          return "Aucune offre trouvée";
        }}
      </Get>
    </div>
  );
}

export default JobPage;
