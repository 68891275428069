import React, { useEffect } from "react";
import "./Search.css";
import JobOffer from "../../JobOffer";
import News from "../../News";
import Announcement from "../../Announcement";
import moment from "moment";
import Event from "../../Event";

function Hit(props) {
  if(props.hit.publier) {
    switch (props.hit.post_type) {
      case "arch_offres":
        return (
          <JobOffer
            user={props.hit["author-displayname"]}
            userId={props.hit["author-ID"]}
            idOffer={props.hit["post_id"]}
            date={moment.unix(props.hit["post_date"]).add(1, "hour").format()}
            description={props.hit["message"]}
            avatar={props.hit["author-picture"]}
          ></JobOffer>
        );
        break;
      case "arch_mag":
        if(props.hit["publier"] === true){
            if (props.hit["taxonomies"].arch_cat_mag[0] === "Événement") {
              return (
                <Event
                  user={props.hit["author-displayname"]}
                  avatar={props.hit["author-picture"]}
                  idEvent={props.hit["post_id"]}
                  text={props.hit["message"]}
                  userId={props.hit["author-ID"]}
                  date={props.hit["date"]}
                  hour={props.hit["heure"]}
                  localisation={props.hit["lieu"]}
                  datePost={moment.unix(props.hit["post_date"]).subtract(2, "hour").format()}
                  banner={props.hit["post-thumbnail"]["app-size"] !== "" && props.hit["post-thumbnail"]["app-size"]}
                  bannerFull={props.hit["post-thumbnail"]["full"] !== "" && props.hit["post-thumbnail"]["full"]}
                ></Event>
              );
            } else {
              return (
                <News
                  user={props.hit["author-displayname"]}
                  avatar={props.hit["author-picture"]}
                  idNews={props.hit["post_id"]}
                  text={props.hit["message"]}
                  userId={props.hit["author-ID"]}
                  date={moment.unix(props.hit["post_date"]).subtract(2, "hour").format()}
                  banner={props.hit["post-thumbnail"]["app-size"] !== "" && props.hit["post-thumbnail"]["app-size"]}
                  bannerFull={props.hit["post-thumbnail"]["full"] !== "" && props.hit["post-thumbnail"]["full"]}
                  title={props.hit["post_title"]}
                ></News>
              );
            }
        }
        break;
      case "arch_annonces":
        if (
          (!props.hit["author-entreprise"] && window.userData.acf.informe_offres_users) ||
          props.hit["author-entreprise"]
        ) {
          return (
            <Announcement
              user={props.hit["author-displayname"]}
              content={props.hit["content"]}
              avatar={props.hit["author-picture"]}
              date={moment.unix(props.hit["post_date"]).subtract(2, "hour").format()}
              avatarCompany={props.hit["author-entreprise-logo"]}
              userId={props.hit["author-ID"]}
              mail={props.hit["author-mail"]}
              id={props.hit["post_id"]}
              image={props.hit["post-thumbnail"]["app-size"] !== "" && props.hit["post-thumbnail"]["app-size"]}
              imageFull={props.hit["post-thumbnail"]["full"] !== "" && props.hit["post-thumbnail"]["full"]}
            ></Announcement>
          );
        }
        break;
      default:
    }
  }
  return <div></div>;
}

export default Hit;
