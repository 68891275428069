import React from "react";
import Header from "../../Header";
import ScrollToTop from "../../../utils/scrollTotop";
import "./NewsPage.css";
import useFetch from "../../../hooks/useFetch";
import Loader from "../../Loader";
import Moment from "react-moment";
import "moment/locale/fr";
import { NavLink } from "react-router-dom";

function NewsPage(props) {
  ScrollToTop();

  const newsId = window.location.pathname.replace("/actualite/", "");
  const { response, error, loading } = useFetch(process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_mag/" + newsId, {
    query: { _embed: 1 },
  });

  if (loading) {
    return (
      <div>
        <Header pageTitle="Actualité"></Header>
        <div className="event-page__content-loading">
          <Loader></Loader>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <Header pageTitle="Actualité"></Header>
        <div className="error">Une erreur s'est produite</div>
      </div>
    );
  }

  if (response.acf.publier_app) {
    return (
      <div className="news-page">
        <Header pageTitle="Actualité"></Header>
        <div className="news-page__banner img-cover">
          <picture>
            <source
              media="(min-width: 480px)"
              srcSet={response._embedded["wp:featuredmedia"][0].media_details && response._embedded["wp:featuredmedia"][0].media_details.sizes["full"].source_url}
            ></source>
            <img src={response._embedded["wp:featuredmedia"][0].media_details && response._embedded["wp:featuredmedia"][0].media_details.sizes["app-size"].source_url} alt="news"></img>
          </picture>
        </div>
        <div className="news-page__avatar-over-banner-wrapper">
          <div className="news-page__avatar-over-banner">
            <img src={response._embedded.author[0].acf.profil_users.url} alt="" />
          </div>
        </div>
        <div className="news-page__content-wrapper">
          <div className="news-page__content">
            <div className="news-page__post-date">
              <p className="small-text small-text--blue">
                Publié le{" "}
                <Moment locale="fr" format="D MMMM YYYY">
                  {response.date}
                </Moment>
                , par{" "}
                {response._embedded.author[0].id !== 87 ?
                  <NavLink to={"/profil/" + response._embedded.author[0].id}>
                    <span className="link bold-text secondary-text">{response._embedded.author[0].name}</span>
                  </NavLink>
                
                :               
                <span className="link bold-text secondary-text">{response._embedded.author[0].name}</span>              
                }
              </p>
            </div>
            <div className="news-page__title">
              <h1>{response.title.rendered}</h1>
            </div>
            <div className="news-page__text" dangerouslySetInnerHTML={{ __html: response.acf.contenu }}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsPage;
