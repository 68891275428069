import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Loader from "../../Loader";
import ReactCrop from "react-image-crop";
import FirstNameInput from "./FirstNameInput";
import LastNameInput from "./LastNameInput";
import { Put } from "react-axios";

const genRanHex = (size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");

function FormSetting(props) {
  useEffect(() => {
    if (window.userData.acf.informe_offres_users) {
      document.getElementById("checkbox-input").checked = true;
    } else {
      document.getElementById("checkbox-input").checked = false;
    }
  }, []);

  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [firstName, setFirstName] = useState(window.userData.name.split(" ")[0]);
  const [lastName, setLastName] = useState(window.userData.name.split(" ")[1]);
  const [toggle, setToggle] = useState(window.userData.acf.informe_offres_users);
  const [crop, setCrop] = useState({ aspect: 1 / 1, unit: "px", width: 80, height: 80 });
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [imageResult, setImageResult] = useState(null);
  const [urlImageUploaded, setUrlImageUploaded] = useState(null);
  const handleFileChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
  };

  function getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    // As Base64 string
    const base64Image = canvas.toDataURL("image/jpeg");
    setImageResult(base64Image);
    // As a blob

    function urltoFile(url, filename, mimeType) {
      return fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType });
        });
    }
    const fileName = `image-${window.userData.id}-${firstName}-${lastName}-${genRanHex(4)}.jpeg`;
    //Usage example:
    urltoFile(base64Image, fileName, "image/jpeg").then(function (file) {
      setLoadingImg(true);
      axios
        .post(process.env.REACT_APP_API_URL + "/wp-json/wp/v2/media", file, {
          headers: {
            Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=",
            "Content-Disposition": `attachment; filename="${fileName}"`,
          },
        })
        .then(function (response) {
          setUrlImageUploaded(response.data.source_url);
          axios
            .put(
              process.env.REACT_APP_API_URL + "/wp-json/acf/v3/users/" + window.userData.id,
              {
                fields: {
                  profil_users: {
                    ID: response.data.id,
                    id: response.data.id,
                  },
                },
              },
              {
                headers: {
                  Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=",
                },
              }
            )
            .then(function (resp) {
              setLoadingImg(false);
              window.location.reload();
            });
        })
        .catch(function (error) {
          setLoadingImg(false);
          console.log(error);
        });
    });
  }

  return (
    <div className="settings__form">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (e.target[1].value) {
            setFirstName(e.target[1].value);
          }
          if (e.target[2].value) {
            setLastName(e.target[2].value);
          }
          setIsReady(true);
          setLoading(true);
        }}
      >
        <div className="settings__form-photo">
          <label className="label bold-text">Photo</label>
          <div className="settings__form-avatar">
            {loadingImg ? <Loader></Loader> : <img src={imageResult ? imageResult : window.userData.acf.profil_users.url} alt=""></img>}
            <div
              className="settings__form-avatar-update"
              onClick={(e) => {
                document.getElementById("input-image").click();
              }}
            >
              Modifier l'image
            </div>
            <input id="input-image" className="settings__form-file" type="file" onChange={handleFileChange} />
          </div>
          {src && (
            <div className="settings__crop-popup">
              <div className="settings__crop-popup-wrapper">
                <ReactCrop src={src} crop={crop} onImageLoaded={setImage} onChange={(newCrop) => setCrop(newCrop)} />{" "}
                <button
                  className="btn"
                  onClick={(e) => {
                    e.preventDefault();
                    getCroppedImg(image, crop);
                    setSrc(null);
                  }}
                >
                  Valider
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setSrc(null);
                    setImage(null);
                  }}
                  className="btn btn-lightness"
                >
                  Annuler
                </button>
              </div>
            </div>
          )}
        </div>
        <FirstNameInput></FirstNameInput>
        <LastNameInput></LastNameInput>

        <div className="settings__notifications">
          <div
            className="toggle r"
            id="toggle-1"
            onClick={(e) => {
              setToggle((v) => !v);
            }}
          >
            <input id="checkbox-input" type="checkbox" className="checkbox"></input>
            <div className="knobs"></div>
            <div className="layer"></div>
          </div>
          <div className="settings__notifications-notice primary-text">Voir les offres commerciales</div>
        </div>
        {loading ? <Loader></Loader> : <></>}
        <button type="submit" className="btn">
          Enregistrer les modifications
        </button>
        <Put
          url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/users/" + window.userData.id}
          method="put"
          data={{
            name: firstName + " " + lastName,
          }}
          instance={axios.create({
            headers: { Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=" },
          })}
          isReady={isReady}
          onLoading={() => {
            axios.put(
              process.env.REACT_APP_API_URL + "/wp-json/acf/v3/users/" + window.userData.id,
              {
                fields: {
                  informe_offres_users: toggle,
                },
              },
              {
                headers: {
                  Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=",
                },
              }
            );
          }}
        >
          {(error, response, isLoading, makeRequest, axios) => {
            if (error) {
              return (
                <div>
                  Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
                </div>
              );
            } else if (isLoading) {
              return <></>;
            } else if (response !== null) {
              setLoading(false);
              new Swal({
                icon: "success",
                title: "Compte mis à jour",
                text: "Nous avons bien mis à jour votre profil",
                type: "success",
              }).then(function () {
                window.location.reload();
              });
            }
            return "";
          }}
        </Put>
      </form>
      <a target="_blank" href={process.env.REACT_APP_API_URL + "/reinitialiser-mot-de-passe/"}>
        <div className="settings__form-change-password">Modifier le mot de passe</div>
      </a>
    </div>
  );
}

export default FormSetting;
