import React, { useState } from "react";
import Header from "../../Header";
import AddAnnouncementInput from "./AddAnnouncementInput";
import ScrollToTop from "../../../utils/scrollTotop";
import "./AddAnnouncement.css";
import { Post } from "react-axios";
import axios from "axios";
import Loader from "../../Loader";
import Swal from "sweetalert2";

const genRanHex = (size) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");

function AddAnnouncement(props) {
  ScrollToTop();
  const [isReady, setIsReady] = useState(false);
  const [postValue, setPostValue] = useState("");
  return (
    <div>
      <Header pageTitle="Poster une annonce"></Header>
      <div className="add-announcement__wrapper">
        <div className="add-announcement__container">
          <div className="add-announcement__title primary-text bold-text">Annonce</div>
          <form
            method="POST"
            onSubmit={(e) => {
              e.preventDefault();
              setPostValue(e.target[0].value);
              setIsReady(true);
            }}
          >
            <AddAnnouncementInput></AddAnnouncementInput>
            <Post
              url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_annonces"}
              method="post"
              data={{
                status: "publish",
                title: genRanHex(12),
                author: window.userData.id,
                content: postValue,
                fields: {
                  content_annonce: postValue,
                },
              }}
              instance={axios.create({
                headers: { Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=" },
              })}
              isReady={isReady}
            >
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return (
                    <div>
                      Something bad happened: {error.message}{" "}
                      <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
                    </div>
                  );
                } else if (isLoading) {
                  return <Loader></Loader>;
                } else if (response !== null) {
                  new Swal({
                    icon: "success",
                    title: "Annonce postée",
                    text: "Félicitations, votre annonce est en ligne",
                    type: "success",
                  }).then(function () {
                    window.location = window.location.origin + "/mon-profil";
                  });
                }
                return "";
              }}
            </Post>
            <button type="submit" className="btn">
              Publier
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddAnnouncement;
