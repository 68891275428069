import React, { useEffect, useState } from "react";
import Loader from "../../Loader";
import JobOffer from "../../JobOffer";
import axios from "axios";

function JobOffers(companyId) {
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (hasMore) {
      setLoading(true);
      let cancel;
      axios
        .get(process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_offres", {
          params: {
            _embed: 1,
            author: companyId.companyId,
            per_page: 6,
            page: pageNumber,
          },
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
        .then(function (response) {
          if (Number(response.headers["x-wp-totalpages"]) === pageNumber) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setData([...data, ...response.data]);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setError(true);
        })
        .then(function () {
          // always executed
        });
      return () => cancel();
    }
  }, [pageNumber]);

  // User bottom page
  useEffect(() => {
    function handleBottom() {
      // si l'utilisateur atteind le bas de la page, on charge la page suivante
      if (document.getElementById("root").getBoundingClientRect().bottom <= window.innerHeight + 50) {
        // on ajoute 50 pour lancer le chargement légèrement avant qu'on atteingne le bas de la page
        if (!loading && hasMore) {
          setPageNumber((v) => v + 1);
        }
      }
    }
    document.addEventListener("scroll", handleBottom);

    return function () {
      document.removeEventListener("scroll", handleBottom);
    };
  }, [loading, hasMore]);

  return (
    <div>
      {data ? (
        <div>
          {data.map((job, index) => {
            return (
              <JobOffer
                key={index}
                idOffer={job.id}
                title={job.title.rendered}
                description={job.acf.texte_app}
                user={job._embedded.author[0].name}
                userId={job._embedded.author[0].id}
                avatar={job._embedded.author[0].acf.profil_users.url}
                date={job.date}
              ></JobOffer>
            );
          })}
          {data.length === 0 && !loading && <p className="center-text">Aucune offre postée</p>}
        </div>
      ) : (
        error && <div className="job-page__content">Une erreur s'est produite</div>
      )}
      {loading && <Loader></Loader>}
    </div>
  );
}

export default JobOffers;
