import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../Loader";
import News from "../../News";
import axios from "axios";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function NewsList(companyId) {
  ScrollToTop();

  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (hasMore) {
      setLoading(true);
      let cancel;
      axios
        .get(process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_mag/", {
          params: {
            _embed: 1,
            author: companyId.companyId,
            per_page: 4,
            page: pageNumber,
          },
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
        .then(function (response) {
          if (Number(response.headers["x-wp-totalpages"]) === pageNumber) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setData([...data, ...response.data]);
          setLoading(false);
        })
        .catch(function (error) {
          setError(true);
        })
        .then(function () {
          // always executed
        });
      return () => cancel();
    }
  }, [pageNumber]);

  // User bottom page
  useEffect(() => {
    function handleBottom() {
      // si l'utilisateur atteind le bas de la page, on charge la page suivante
      if (document.getElementById("root").getBoundingClientRect().bottom <= window.innerHeight + 50) {
        // on ajoute 50 pour lancer le chargement légèrement avant qu'on atteingne le bas de la page
        if (!loading && hasMore) {
          setPageNumber((v) => v + 1);
        }
      }
    }
    document.addEventListener("scroll", handleBottom);

    return function () {
      document.removeEventListener("scroll", handleBottom);
    };
  }, [loading, hasMore]);

  return (
    <div>
      {data ? (
        <div>
          {data.map((news, index) => {
            if (news.arch_cat_mag[0] !== 14 && news.acf.publier_app) {
              return (
                <News
                  key={index}
                  avatar={news._embedded.author[0].acf.profil_users.url}
                  idNews={news.id}
                  banner={news._embedded["wp:featuredmedia"][0].media_details && news._embedded["wp:featuredmedia"][0].media_details.sizes["thumbnail"].source_url}
                  bannerFull={news._embedded["wp:featuredmedia"][0].media_details && news._embedded["wp:featuredmedia"][0].media_details.sizes["full"].source_url}
                  text={news.acf.texte_app}
                  userId={news.author}
                  user={news._embedded.author[0].name}
                  date={news.date}
                  title={news.title.rendered}
                ></News>
              );
            }
          })}
        </div>
      ) : (
        error && <div className="">Une erreur s'est produite</div>
      )}
      {loading && (
        <div className="news__content">
          <Loader></Loader>
        </div>
      )}
    </div>
  );
}

export default NewsList;
