import React from "react";
import "../user/UserPage.css";
import ScrollToTop from "../../../utils/scrollTotop";
import Loader from "../../Loader";
import { Get } from "react-axios";
import UserPage from "./UserPage";
import CompanyPage from "../company/CompanyPage";
import axios from "axios";

// On décide ici soit de rendre le component UserPage soit le component Entreprise en fonction du type d'utilisateur
function SwitchProfil(props) {
  ScrollToTop();
  const userId = window.location.pathname.replace("/profil/", "");

  const axiosInstance = axios.create({
    headers: { Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=" },
  });
  return (
    <div>
      <Get url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/hierarchical_users/" + userId} instance={axiosInstance} params={{ _embed: 1 }}>
        {(error, response, isLoading, makeRequest, axios) => {
          if (error) {
            return (
              <div>
                Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
              </div>
            );
          } else if (isLoading) {
            return <Loader></Loader>;
          } else if (response !== null) {
            if ( response.data.type == 'entreprise') {
              return <CompanyPage data={response.data}></CompanyPage>;
            } else {
              return <UserPage response={response.data.id}></UserPage>;
            }
          }
          return "";
        }}
      </Get>
    </div>
  );
}

export default SwitchProfil;