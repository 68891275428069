import React from "react";

function ContactButton({ lightness, mail }) {
  return (
    <a className="link-w" href={"mailto:" + mail} rel="noreferrer" target="_blank">
      <div className={lightness ? "btn btn-lightness" : "btn"}>Contacter</div>
    </a>
  );
}

export default ContactButton;
