import React from "react";
import Header from "../../Header";
import "../user/UserPage.css";
import ScrollToTop from "../../../utils/scrollTotop";
import LinkButton from "../../LinkButton";
import Loader from "../../Loader";
import Announcements from "../announcement/Announcements";
import { Get } from "react-axios";
import axios from "axios";
import { NavLink } from "react-router-dom";

function Profil(props) {
  ScrollToTop();
  var avatarCompanyUrl = "";
  return (
    <div className="user-page">
      <Header pageTitle="Mon profil" linkRightButton="parametres"></Header>
      <Get
        url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/users/" + window.userData.id}
        params={{ _embed: 1 }}
        instance={axios.create({
          headers: { Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=" },
        })}
      >
        {(error, response, isLoading, makeRequest, axios) => {
          if (error) {
            return (
              <div>
                Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
              </div>
            );
          } else if (isLoading) {
            return <Loader></Loader>;
          } else if (response !== null) {
            // On formate l'url de l'avatar de l'entreprise
              if(response.data.acf.entreprise_users) {
                avatarCompanyUrl = response.data.acf.entreprise_users.user_avatar;
                avatarCompanyUrl = avatarCompanyUrl.substring(
                  avatarCompanyUrl.lastIndexOf('src="') + 5,
                  avatarCompanyUrl.lastIndexOf("class=") - 2
                );
              }
            return (
              <div>
                <div className="user-page__wrapper">
                  <div className="user-page__header">
                    <div className="user-page__avatar" style={{ backgroundImage: `url(${response.data.acf.profil_users.url})` }}>
                      <NavLink className="link card-heading__user" to={"/profil/" + response.data.acf.entreprise_users.ID}>
                        <div className="user-page__avatar-company">
                          <img src={avatarCompanyUrl} alt=""></img>
                        </div>
                      </NavLink>
                    </div>
                    <h1 className="user-page__name">{response.data.name}</h1>
                    <div className="user-page__company">{response.data.acf.entreprise_users.display_name}</div>
                    <div className="user-page__job small-text">{response.data.acf.poste_users}</div>
                    <LinkButton link="/parametres" text="Modifier mon profil"></LinkButton>
                  </div>
                </div>
                <div className="user-page__flux">
                  <div className="user-page__flux__wrapper">
                    <div className="user-page__info-flux small-text secondary-text">Mes annonces publiées</div>
                    <Announcements userId={response.data.id}></Announcements>
                  </div>
                </div>
              </div>
            );
          }
          return "";
        }}
      </Get>
    </div>
  );
}

export default Profil;
