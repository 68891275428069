import React from "react";
import "./News.css";
import LinkButton from "./LinkButton";
import CardHeading from "./CardHeading";

import Moment from "react-moment";
function News({ user, avatar, idNews, banner, bannerFull, text, userId, date, title }) {

  return (
    <div className="news animate__animated animate__fadeInUp">
      <CardHeading
        userId={userId}
        user={user}
        avatar={avatar}
        date={
          <Moment locale="fr" fromNow>
            {date}
          </Moment>
        }
        text=" a publié une nouvelle actualité."
      ></CardHeading>
      <div className="news__text">
        <p>{text}</p>
      </div>
      <div className="news__card">
        <div className="news__banner">
          <div className="news__banner-image img-cover">
            <picture>
              <source media="(min-width: 480px)" srcSet={bannerFull}></source>
              <img src={banner} alt="news"></img>
            </picture>
          </div>
          <div className="news__avatar-over-banner">
            <img src={avatar} alt="" />
          </div>
        </div>
        <div className="news__content bold-text">{title}</div>
        <LinkButton text="Lire l'actualité" link={"/actualite/" + idNews}></LinkButton>
      </div>
    </div>
  );
}

export default News;
