import React, { Component } from "react";
import Header from "../../Header";
import "./CompanyPage.css";
import ContactButton from "../../ContactButton";
import Employees from "./Employees";
import JobOffers from "../job/JobOffers";
import Events from "../event/Events";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Search from "../search/Search";

class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.state = {
      currentTab: "all",
      center: { lat: 0, lng: 0 },
    };
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount(){    
    if(this.data.has_account === false){
      this.setState({currentTab : "map"})
    }
  }

  changeTab(e) {
    e.preventDefault();
    let target = e.target.id;
    this.setState({ currentTab: target });
  }

  mapStyle = [
    { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
    { featureType: "administrative.land_parcel", elementType: "labels.text.fill", stylers: [{ color: "#bdbdbd" }] },
    { featureType: "poi", elementType: "geometry", stylers: [{ color: "#eeeeee" }] },
    { featureType: "poi", elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
    { featureType: "poi.park", elementType: "geometry", stylers: [{ color: "#e5e5e5" }] },
    { featureType: "poi.park", elementType: "labels.text.fill", stylers: [{ color: "#9e9e9e" }] },
    { featureType: "road", elementType: "geometry", stylers: [{ color: "#ffffff" }] },
    { featureType: "road.arterial", elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
    { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#dadada" }] },
    { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { featureType: "road.local", elementType: "labels.text.fill", stylers: [{ color: "#9e9e9e" }] },
    { featureType: "transit.line", elementType: "geometry", stylers: [{ color: "#e5e5e5" }] },
    { featureType: "transit.station", elementType: "geometry", stylers: [{ color: "#eeeeee" }] },
    { featureType: "water", elementType: "geometry", stylers: [{ color: "#c9c9c9" }] },
    { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#9e9e9e" }] },
  ];

  render() {
    const { currentTab } = this.state;
    return (
      <div className="compagny-page">        
        <Header pageTitle=""></Header>
        <div className="company-page__header-wrapper">
          <div className="company-page__header">
            <div className="company-page__avatar">
              <div className="img-cover">
                <img src={this.data.logo} alt=""></img>
              </div>
            </div>
            <h1 className="company-page__name">{this.data.name}</h1>
            <div className="company-page__company-type">{this.data.libelle}</div>
            <div className="company-page__tag small-text">
              {this.data.tags.map((tag, index) => {
                return "#" + tag + " ";
              })}
            </div>
            <ContactButton mail={this.data.email}></ContactButton>
            <div className="company-page__link">
              {this.data.site_web ? (
                <a target="_blank" rel="noreferrer" href={this.data.site_web}>
                  <div className="btn btn-lightness">Site web</div>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {this.data.has_account === false ? <></> :
        <div className="company-page__sub-header">
          <div className="company-page__navbar">
            <div id="all" className={`company-page__navlink ${currentTab === "all" ? "active" : ""}`} onClick={this.changeTab}>
              <svg id="all" xmlns="http://www.w3.org/2000/svg" width="21" height="10" viewBox="0 0 21 10">
                <path
                  id="all"
                  data-name="Combined Shape"
                  d="M6.974,10c-.383,0-.695-.2-.695-.436v-1.4c0-.24.311-.437.695-.437H20.306c.383,0,.694.2.694.437v1.4c0,.241-.31.436-.694.436ZM3.834,6.251c-.383,0-.694-.2-.694-.437v-1.4c0-.24.311-.436.694-.436H17.166c.383,0,.695.2.695.436v1.4c0,.241-.311.437-.695.437ZM.694,2.269C.31,2.269,0,2.074,0,1.832V.437C0,.2.31,0,.694,0H14.027c.383,0,.694.2.694.437v1.4c0,.241-.311.437-.694.437Z"
                />
              </svg>
            </div>
            <div id="employees" className={`company-page__navlink ${currentTab === "employees" ? "active" : ""}`} onClick={this.changeTab}>
              <svg id="employees" xmlns="http://www.w3.org/2000/svg" width="37" height="20" viewBox="0 0 37 20">
                <path
                  id="employees"
                  data-name="Combined Shape"
                  d="M11.269,20h0a.867.867,0,0,1-.819-.831,8.4,8.4,0,0,1,2.863-6.39A6.388,6.388,0,0,0,8.046,9.961H8a6.542,6.542,0,0,0-6.368,6.688.865.865,0,0,1-.8.858H.819A.868.868,0,0,1,0,16.675a8.388,8.388,0,0,1,1.445-4.782A8.148,8.148,0,0,1,5.187,8.87,4.743,4.743,0,0,1,3.228,4.987,4.912,4.912,0,0,1,8.051,0a4.913,4.913,0,0,1,4.823,4.987,4.741,4.741,0,0,1-1.947,3.87,8.031,8.031,0,0,1,3.705,2.974,7.911,7.911,0,0,1,.992-.467,4.8,4.8,0,0,1-1.947-3.883,4.825,4.825,0,1,1,9.645,0,4.817,4.817,0,0,1-1.909,3.883,8.069,8.069,0,0,1,.968.467A8.018,8.018,0,0,1,26.06,8.857a4.773,4.773,0,0,1-1.934-3.87,4.826,4.826,0,1,1,9.646,0A4.78,4.78,0,0,1,31.85,8.869a8.16,8.16,0,0,1,1.307.676,8.27,8.27,0,0,1,2.811,3.017A8.5,8.5,0,0,1,37,16.61a.817.817,0,1,1-1.633,0,6.655,6.655,0,0,0-3.052-5.662,6.23,6.23,0,0,0-3.346-.985,6.387,6.387,0,0,0-5.257,2.828,8.424,8.424,0,0,1,2.839,6.26.877.877,0,0,1-.816.9.931.931,0,0,1-.816-.87A6.564,6.564,0,0,0,18.5,12.455H18.45a6.26,6.26,0,0,0-4.528,1.972,6.733,6.733,0,0,0-1.839,4.716.864.864,0,0,1-.8.857ZM18.5,4.156a3.275,3.275,0,0,0-3.216,3.325,3.184,3.184,0,0,0,3.162,3.325H18.5a3.275,3.275,0,0,0,3.215-3.325A3.275,3.275,0,0,0,18.5,4.156ZM28.949,1.662a3.275,3.275,0,0,0-3.215,3.325,3.217,3.217,0,1,0,6.43,0A3.275,3.275,0,0,0,28.949,1.662Zm-20.9,0A3.275,3.275,0,0,0,4.836,4.987,3.275,3.275,0,0,0,8.051,8.311a3.275,3.275,0,0,0,3.216-3.325A3.275,3.275,0,0,0,8.051,1.662Z"
                  transform="translate(0 0)"
                />
              </svg>
            </div>
            <div id="jobOffer" className={`company-page__navlink ${currentTab === "jobOffer" ? "active" : ""}`} onClick={this.changeTab}>
              <svg id="jobOffer" xmlns="http://www.w3.org/2000/svg" width="23" height="20" viewBox="0 0 23 20">
                <path
                  id="jobOffer"
                  data-name="Combined Shape"
                  d="M20.546,20H2.454a1.374,1.374,0,0,1-1.39-1.354V11.1C.844,11,.608,10.9.374,10.792A.648.648,0,0,1,0,10.21l.013-5.63A1.373,1.373,0,0,1,1.4,3.226H7.556V1.805A1.831,1.831,0,0,1,9.408,0h4.185a1.831,1.831,0,0,1,1.852,1.805V3.226H21.6a1.375,1.375,0,0,1,1.39,1.354L23,10.063a.646.646,0,0,1-.354.573c-.222.113-.455.226-.711.343v7.667A1.374,1.374,0,0,1,20.546,20ZM2.391,11.6v7.042a.065.065,0,0,0,.063.061H20.546a.069.069,0,0,0,.064-.061V11.524a24.13,24.13,0,0,1-6.738,1.348v.508a1.053,1.053,0,0,1-1.066,1.038H10.2A1.053,1.053,0,0,1,9.13,13.379v-.5A25.391,25.391,0,0,1,2.391,11.6Zm8.064.283v1.238h2.09V11.887ZM1.4,4.519a.066.066,0,0,0-.064.062L1.328,9.8a22.634,22.634,0,0,0,7.8,1.783,1.054,1.054,0,0,1,1.065-.989h2.611a1.055,1.055,0,0,1,1.063.978,21.221,21.221,0,0,0,7.8-1.9l-.011-5.088a.067.067,0,0,0-.064-.063ZM9.408,1.292a.52.52,0,0,0-.527.513V3.226h5.238V1.805a.527.527,0,0,0-.527-.513Z"
                />
              </svg>
            </div>
            <div id="event" className={`company-page__navlink ${currentTab === "event" ? "active" : ""}`} onClick={this.changeTab}>
              <svg id="event" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path
                  id="event"
                  data-name="Combined Shape"
                  d="M17.162,20H2.838A2.874,2.874,0,0,1,0,17.1V4.8A2.874,2.874,0,0,1,2.838,1.9h.943V.853a.835.835,0,1,1,1.67,0V1.9h9.1V.853a.835.835,0,1,1,1.669,0V1.9h.943A2.874,2.874,0,0,1,20,4.8V17.1A2.874,2.874,0,0,1,17.162,20ZM1.669,8.061V17.1a1.184,1.184,0,0,0,1.169,1.2H17.162a1.184,1.184,0,0,0,1.169-1.2V8.061ZM2.838,3.6A1.184,1.184,0,0,0,1.669,4.8V6.354H18.331V4.8a1.184,1.184,0,0,0-1.169-1.2h-.943v.633a.835.835,0,1,1-1.669,0V3.6H5.45v.633a.835.835,0,1,1-1.67,0V3.6Z"
                />
              </svg>
            </div>
            <div id="map" className={`company-page__navlink ${currentTab === "map" ? "active" : ""}`} onClick={this.changeTab}>
              <svg id="map" xmlns="http://www.w3.org/2000/svg" width="27.688" height="23.486" viewBox="0 0 27.688 23.486">
                <defs>
                  <clipPath id="map">
                    <path id="map" data-name="Clip 2" d="M0,0H11.558V14.634H0Z" transform="translate(0.066 0)" fill="none" />
                  </clipPath>
                  <clipPath id="map">
                    <path id="map" data-name="Clip 7" d="M0,0H27.688V16.681H0Z" transform="translate(-8 6.806)" fill="none" />
                  </clipPath>
                </defs>
                <g id="map" data-name="Group Copy 3" transform="translate(8 0)">
                  <path id="map" data-name="Clip 2" d="M0,0H11.558V14.634H0Z" transform="translate(0.066 0)" fill="none" />
                  <g id="map" data-name="Group Copy 3">
                    <path
                      id="map"
                      data-name="Fill 1"
                      d="M5.779,14.634a.754.754,0,0,1-.544-.237C4.7,13.831,0,8.777,0,5.779A5.777,5.777,0,0,1,5.779,0a5.786,5.786,0,0,1,5.779,5.78c0,3.006-4.7,8.053-5.234,8.618A.754.754,0,0,1,5.779,14.634Zm0-13.135A4.277,4.277,0,0,0,1.5,5.779c0,1.73,2.689,5.21,4.279,6.994.714-.805,4.282-4.927,4.28-6.993A4.285,4.285,0,0,0,5.779,1.5Z"
                      transform="translate(0.066)"
                    />
                    <path
                      id="map"
                      data-name="Fill 4"
                      d="M1.724,0A1.724,1.724,0,1,1,0,1.724,1.724,1.724,0,0,1,1.724,0"
                      transform="translate(4.12 4.474)"
                    />
                  </g>
                  <path id="map" data-name="Clip 7" d="M0,0H27.688V16.681H0Z" transform="translate(-8 6.806)" fill="none" />
                  <g id="map" data-name="Group Copy 3">
                    <path
                      id="map"
                      data-name="Fill 6"
                      d="M27.655,13.84,23.946,1.7a.748.748,0,0,0-.436-.476L20.487,0a7.959,7.959,0,0,1-.481,1.423l2.611,1.058L25.836,13.01l-4.791-1.191L19.384,2.663A21.123,21.123,0,0,1,18.2,4.527l1.359,7.5-5.711,3.056-5.71-3.056,1.358-7.5C9.069,3.928,8.664,3.3,8.3,2.661l-1.66,9.158L1.854,13.01,5.073,2.481,7.684,1.423A8.071,8.071,0,0,1,7.2,0L4.178,1.226a.753.753,0,0,0-.436.476L.033,13.84a.742.742,0,0,0,.172.733.755.755,0,0,0,.727.213L7.2,13.226l6.293,3.367a.752.752,0,0,0,.354.088.743.743,0,0,0,.354-.088l6.294-3.367,6.266,1.56a.748.748,0,0,0,.9-.946"
                      transform="translate(-8 6.806)"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        }
        {currentTab === "all" ? (
          <div className="company-page__flux">
            <div className="compagny-page__flux-wrapper">
              <div className="homepage__flux-title">Fil d'actualité</div>
              {/**<GlobalFeed companyId={this.data.id}></GlobalFeed> */}
              <Search searchValue={this.data.name} displayMenu={true}></Search>
            </div>
          </div>
        ) : (
          ""
        )}

        {currentTab === "employees" ? (
          <div className="company-page__flux">
            <div className="compagny-page__flux-wrapper">
              <div className="homepage__flux-title">Employés</div>
              <div className="company-page__employees-list">
                <Employees companyId={this.data.id}></Employees>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {currentTab === "jobOffer" ? (
          <div className="company-page__flux">
            <div className="compagny-page__flux-wrapper">
              <div className="homepage__flux-title">Offres d'emploi</div>
              <JobOffers companyId={this.data.id}></JobOffers>
            </div>
          </div>
        ) : (
          ""
        )}

        {currentTab === "event" ? (
          <div className="company-page__flux">
            <div className="compagny-page__flux-wrapper">
              <div className="homepage__flux-title">Événements</div>
              <Events companyId={this.data.id}></Events>
            </div>
          </div>
        ) : (
          ""
        )}

        {currentTab === "map" ? (
          <div className="company-page__map">
            <LoadScript googleMapsApiKey="AIzaSyCjG9v2FRNzCVi4ACktMCibxG75K2ae6DQ">
              <GoogleMap
                onLoad={() => {
                  const geocoder = new window.google.maps.Geocoder();
                  geocoder.geocode({ address: this.data.adresse.address }, (results, status) => {
                    if (status === "OK") {
                      let point = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
                      document.getElementById("map");
                      this.setState({ center: point });
                    } else {
                      console.log("Geocode was not successful for the following reason: " + status);
                    }
                  });
                }}
                center={this.state.center}
                mapContainerStyle={{
                  width: "100%",
                  height: "80vh",
                  marginBottom: "83px",
                }}
                zoom={16}
                options={{ disableDefaultUI: true, styles: this.mapStyle }}
              >
                <Marker position={this.state.center} icon="/images/assets/pin-map-archparc.png"></Marker>
                <div className="company-page__address-banner">
                  <div className="compagny-page__address-building">{this.data.batiment}</div>
                  <div className="compagny-page__address ">
                    <p>{this.data.adresse.address}</p>
                  </div>
                </div>
              </GoogleMap>
            </LoadScript>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CompanyPage;
