import React from "react";
import "./Event.css";
import LinkButton from "./LinkButton";
import CardHeading from "./CardHeading";
import Moment from "react-moment";

function Event({ avatar, idEvent, date, hour, localisation, banner, bannerFull, text, userId, user, datePost }) {
  return (
    <div className="event animate__animated animate__fadeInUp">
      <CardHeading
        userId={userId}
        user={user}
        avatar={avatar}
        date={
          <Moment locale="fr" fromNow>
            {datePost}
          </Moment>
        }
        text=" organise un événement prochainement."
      ></CardHeading>
      <div className="event__text">
        <p>{text}</p>
      </div>
      <div className="event__card">
        <div className="event__banner">
          <div className="event__banner-image img-cover">
            <picture>
              <source media="(min-width: 480px)" srcSet={bannerFull}></source>
              <img src={banner} alt="news"></img>
            </picture>
          </div>
          <div className="event__avatar-over-banner">
            <img src={avatar} alt="" />
          </div>
        </div>
        <div className="event__content">
          <div className="event__date primary-text">
            {date} à {hour && hour.replace(":", "h")}
          </div>
          <div className="event__location small-text">{localisation}</div>
        </div>
        <LinkButton text="Voir l'événement" link={"/evenement/" + idEvent}></LinkButton>
      </div>
    </div>
  );
}

export default Event;
