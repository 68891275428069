import React from "react";
import { NavLink } from "react-router-dom";
import "./PhoneBook.css";

function Contact({ name, avatar, id, poste, tags }) {
  return (
    <NavLink className="contact__link" to={"/profil/" + id}>
      <div className="contact__container">
        <div className="contact__avatar">
          <img src={avatar} alt=""></img>
        </div>
        <div className="contact__infos">
          <div className="contact__name">{name}</div>
          <div className="contact__tags">{poste}</div>
          <div className="contact__tags">
            {tags
              ? tags.map((tag, index) => {
                  return "#" + tag + " ";
                })
              : ""}
          </div>
        </div>
      </div>
    </NavLink>
  );
}

export default Contact;
