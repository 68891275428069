import React from "react";
import Header from "../../Header";
import "./UserPage.css";
import ContactButton from "../../ContactButton";
import ScrollToTop from "../../../utils/scrollTotop";
import Announcements from "../announcement/Announcements";
import { Get } from "react-axios";
import Loader from "../../Loader";
import axios from "axios";

function UserPage(data) {
  ScrollToTop();

  

  function setAvatar() {
  }
  setAvatar();
  const axiosInstance = axios.create({
    headers: { Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=" },
  });
  return (
    <div className="user-page">
      <Header pageTitle=""></Header>
      <Get url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/users/" + data.response} instance={axiosInstance} params={{ _embed: 1 }}>
        {(error, response, isLoading, makeRequest, axios) => {
          if (error) {
            return (
              <div>
                Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
              </div>
            );
          } else if (isLoading) {
            return <Loader></Loader>;            
          } else if (response !== null) {
            let avatarCompanyUrl = "";
            let nameCompany = "";
            if (response.data.acf.entreprise_users) {
              avatarCompanyUrl = response.data.acf.entreprise_users.user_avatar;
              avatarCompanyUrl = avatarCompanyUrl.substring(avatarCompanyUrl.lastIndexOf('src="') + 5, avatarCompanyUrl.lastIndexOf("class=") - 2);
              nameCompany = response.data.acf.entreprise_users.display_name;
            }
            return(
              <div>
                <div className="user-page__wrapper">
                  <div className="user-page__header">
                    <div className="user-page__avatar" style={{ backgroundImage: `url(${response.data.acf.profil_users.url})` }}>
                      {avatarCompanyUrl && (
                        <div className="user-page__avatar-company">
                          <img src={avatarCompanyUrl} alt=""></img>
                        </div>
                      )}
                    </div>
                    <h1 className="user-page__name">{response.data.name}</h1>
                    <div className="user-page__company">{nameCompany}</div>
                    <div className="user-page__job small-text">{response.data.acf.poste_users}</div>
                    <ContactButton mail={response.data.user_email}></ContactButton>
                  </div>
                </div>
                <div className="user-page__flux">
                  <div className="user-page__flux__wrapper">
                    <div className="user-page__info-flux small-text secondary-text">Les annonces publiées par {response.data.name}</div>
                    <Announcements userId={response.data.id}></Announcements>
                  </div>
                </div>
              </div>
            )     
          }
          return "";
        }}
      </Get>
    </div>
  );
}

export default UserPage;
