import React, { Component } from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import PropTypes from "prop-types";
import Hit from "./Hit";

class InfiniteHits extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refine: PropTypes.func.isRequired,
  };

  sentinel = null;

  onSentinelIntersection = (entries) => {
    const { hasMore, refine } = this.props;

    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        refine();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits } = this.props;

    return (
      <div className="ais-InfiniteHits">
        <ul className="ais-InfiniteHits-list">
          {hits.length === 0 && <p className="center-text">Auncun résultat</p>}
          {hits.map((hit) => (
            <li key={hit.post_id} className="ais-InfiniteHits-item">
              <Hit hit={hit} />
            </li>
          ))}
          <li className="ais-InfiniteHits-sentinel" ref={(c) => (this.sentinel = c)} />
        </ul>
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);
