import React, { Component } from "react";
import "./PhoneBook.css";
import PropTypes from "prop-types";

class SearchInput extends Component {
  static defaultProps = {
    name: "search",
    placeholder: "Rechercher par nom, activité, tags…",
    required: true,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  handleChange = ({ target: { value } }) => {
    value = value.toLowerCase();
    this.props.onUpdate(value);
    this.setState({ value });
  };

  render() {
    const { name, placeholder, required } = this.props;
    return (
      <input
        className="searchInput"
        name={name}
        onChange={this.handleChange}
        placeholder={placeholder}
        required={required}
        type="text"
        value={this.state.value}
      />
    );
  }
}

export default SearchInput;
