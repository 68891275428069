import React from "react";
import { NavLink } from "react-router-dom";

import "./Menu.css";

const Menu = (props) => {
  return (
    <div id="menu" className="menu">
      <div className="menu__wrapper">
        <NavLink exact to="/" className="menu__link">
          <svg
            className="menu__home-link"
            id="Icons_Home-disable"
            data-name="Icons/Home-disable"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="28"
            viewBox="0 0 32 28"
          >
            <path
              id="Combined_Shape"
              data-name="Combined Shape"
              d="M27.649,27.508H20.028a.724.724,0,0,1-.724-.724v-9.86H12.656v9.86a.724.724,0,0,1-.724.724H4.2a.724.724,0,0,1-.724-.724v-15.7L1.167,12.83a.726.726,0,0,1-1.161-.673.72.72,0,0,1,.279-.48L15.483.155a.706.706,0,0,1,.882,0l15.2,11.523a.726.726,0,1,1-.882,1.153l-2.307-1.748v15.7A.725.725,0,0,1,27.649,27.508ZM11.931,15.476h8.1a.725.725,0,0,1,.725.724v9.86h6.174V10.006a.134.134,0,0,1,0-.019l-11-8.34L4.919,9.987a.191.191,0,0,0,0,.02.19.19,0,0,1,0,.02V26.06h6.287V16.2A.724.724,0,0,1,11.931,15.476Z"
              transform="translate(0 0)"
            />
          </svg>
          <span>Accueil</span>
        </NavLink>
        <NavLink to="/poster-une-annonce" className="menu__link">
          <svg
            className="menu__add-link"
            id="Icons_Add-disable"
            data-name="Icons/Add-disable"
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 27 27"
          >
            <path
              id="Combined_Shape"
              data-name="Combined Shape"
              d="M0,13.4a13.4,13.4,0,1,1,13.4,13.4A13.419,13.419,0,0,1,0,13.4Zm1.551,0A11.853,11.853,0,1,0,13.4,1.552,11.866,11.866,0,0,0,1.551,13.4ZM12.627,20V13.975H6.59a.776.776,0,1,1,0-1.552h6.037V6.385a.776.776,0,0,1,1.552,0v6.038h6.037a.776.776,0,1,1-.014,1.552H14.179v6.037A.776.776,0,1,1,12.627,20Z"
              transform="translate(0 0)"
            />
          </svg>
          <span>Poster</span>
        </NavLink>
        <NavLink to="/annuaire" className=" menu__link">
          <svg
            className="menu__phonebook-link"
            id="icons_annuaire"
            data-name="Icons/Annuaire-disable"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
          >
            <path
              id="Combined_Shape"
              data-name="Combined Shape"
              d="M20.481,27.59H.762A.729.729,0,0,1,0,26.827v-13.6a.735.735,0,0,1,.448-.685L9.859,8.292V4.276a.687.687,0,0,1,.488-.7l7.844-3.5A1.108,1.108,0,0,1,18.668,0l.061.009,8.33,2.721a.886.886,0,0,1,.531.752V26.827a.729.729,0,0,1-.762.762ZM13.939,6.707l6.777,2.37a.735.735,0,0,1,.527.7V26.065h4.821V4.021l-7.5-2.426L11.384,4.8V7.6l1.99-.9a.626.626,0,0,1,.278-.061A.647.647,0,0,1,13.939,6.707Zm.448,19.358h5.332V10.308L14.387,8.465ZM1.525,13.694V26.065H12.863V8.6Zm.459,10.753a.729.729,0,0,1,.762-.762l8.037-.392a.762.762,0,1,1,.014,1.525l-8.042.391A.731.731,0,0,1,1.984,24.448Zm20.682-1.134V21.1a.762.762,0,0,1,1.525,0v2.209a.762.762,0,1,1-1.525,0ZM1.987,21.539a.667.667,0,0,1,.111-.511.845.845,0,0,1,.559-.343l8.058-.851a.649.649,0,0,1,.493.113.848.848,0,0,1,.344.56.66.66,0,0,1-.112.5.847.847,0,0,1-.559.345l-8.135.852A.8.8,0,0,1,1.987,21.539Zm20.678-2.192V17.138a.762.762,0,0,1,1.525,0v2.209a.762.762,0,0,1-1.525,0ZM2.746,19.26a.752.752,0,0,1-.759-.611.8.8,0,0,1,.126-.6.735.735,0,0,1,.487-.31l8.038-1.7a.793.793,0,0,1,.6.126.733.733,0,0,1,.31.486.788.788,0,0,1-.128.6.73.73,0,0,1-.487.31L2.88,19.26Zm0-3a.875.875,0,0,1-.742-.5.709.709,0,0,1,.013-.576.835.835,0,0,1,.5-.434l8.051-2.494a.687.687,0,0,1,.546.025.83.83,0,0,1,.434.5.7.7,0,0,1-.023.55.838.838,0,0,1-.5.434l-8.078,2.5Zm19.92-.876V13.115a.762.762,0,0,1,1.525,0v2.266a.762.762,0,1,1-1.525,0Zm0-4.023V9.149a.762.762,0,0,1,1.525,0v2.209a.762.762,0,1,1-1.525,0Zm0-3.966V5.182a.762.762,0,0,1,1.525,0v2.21a.762.762,0,0,1-1.525,0Z"
              transform="translate(0)"
            />
          </svg>
          <span>Annuaire</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Menu;
