import React, { useState } from "react";
import Contact from "./Contact";
import "./PhoneBook.css";
import SearchInput from "./SearchInput";
import ScrollToTop from "../../../utils/scrollTotop";
import Loader from "../../Loader";
import { Get } from "react-axios";

function PhoneBook(props) {
  ScrollToTop();
  let previousLetter = "";

  let contactList = "";
  const [noResult, setNoResult] = useState(false);
  // filtering results function
  let filterByText = (value) => {
    contactList = document.getElementsByClassName("contact__name");
    let companiesLeft = contactList.length;
    for (let index = 0; index < contactList.length; index++) {
      // If value is not in name and not in tags
      if (
        !contactList[index].innerHTML.toLowerCase().includes(value.toLowerCase()) &&
        !contactList[index].nextSibling.nextSibling.innerHTML.toLowerCase().includes(value.toLowerCase())
      ) {
        // alors on cache l'élément et on vérifie s'il faut aussi cacher la lettre de separation
        contactList[index].parentNode.parentNode.style.display = "none";
        companiesLeft--;
        if (contactList[index].parentNode.parentNode.parentNode.previousSibling.classList.contains("phonebook__letter-separator")) {
          contactList[index].parentNode.parentNode.parentNode.previousSibling.style.display = "none";
        }
      } else {
        if (contactList[index].parentNode.parentNode.parentNode.previousSibling.classList.contains("phonebook__letter-separator")) {
          contactList[index].parentNode.parentNode.parentNode.previousSibling.style.display = "block";
        }
        contactList[index].parentNode.parentNode.style.display = "flex";
      }
    }
    if (companiesLeft === 0) {
      setNoResult(true);
    } else {
      setNoResult(false);
    }
  };
  return (
    <div>
      <div className="phonebook__header">
        <div className="phonebook__searchbar">
          <div className="phonebook__header-icon">
            <img src={window.location.origin + "/images/assets/icons-search.svg"} alt=""></img>
          </div>
          <SearchInput onUpdate={filterByText}></SearchInput>
        </div>
      </div>

      <div className="phonebook__wrapper">
        <div className="phonebook__container">
          <div className="phonebook__navigation">
            <a href="#a">
              <span>a</span>
            </a>
            <a href="#b">
              <span>b</span>
            </a>
            <a href="#c">
              <span>c</span>
            </a>
            <a href="#d">
              <span>d</span>
            </a>
            <a href="#e">
              <span>e</span>
            </a>
            <a href="#f">
              <span>f</span>
            </a>
            <a href="#h">
              <span>h</span>
            </a>
            <a href="#i">
              <span>i</span>
            </a>
            <a href="#j">
              <span>j</span>
            </a>
            <a href="#k">
              <span>k</span>
            </a>
            <a href="#l">
              <span>l</span>
            </a>
            <a href="#m">
              <span>m</span>
            </a>
            <a href="#n">
              <span>n</span>
            </a>
            <a href="#o">
              <span>o</span>
            </a>
            <a href="#p">
              <span>p</span>
            </a>
            <a href="#q">
              <span>q</span>
            </a>
            <a href="#r">
              <span>r</span>
            </a>
            <a href="#s">
              <span>s</span>
            </a>
            <a href="#t">
              <span>t</span>
            </a>
            <a href="#u">
              <span>u</span>
            </a>
            <a href="#v">
              <span>v</span>
            </a>
            <a href="#w">
              <span>w</span>
            </a>
            <a href="#x">
              <span>x</span>
            </a>
            <a href="#y">
              <span>y</span>
            </a>
            <a href="#z">
              <span>z</span>
            </a>
          </div>
          <div className="phonebook__list">
            <Get url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/hierarchical_users"}>
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return <div className="event-page__content">Something bad happened: {error.message}</div>;
                } else if (isLoading) {
                  return (
                    <div className="news__content">
                      <Loader></Loader>
                    </div>
                  );
                } else if (response !== null) {
                  // order by alphabet
                  let contactList = Object.values(response.data).sort((a, b) =>
                    a.name.toLowerCase().charCodeAt(0) > b.name.toLowerCase().charCodeAt(0) ? 1 : -1
                  );
                  return (
                    <div>
                      {noResult && <p>Aucune entreprise ne correspond à votre recherche</p>}
                      {contactList.map((contact, index) => {
                        if(contact.date_depart === "") {
                          if (previousLetter !== contact.name[0]) {
                            previousLetter = contact.name[0];
                            return (
                              <div key={"letter-separator" + previousLetter}>
                                <div id={previousLetter.toLowerCase()} className="phonebook__letter-separator bold-text">
                                  {previousLetter}
                                </div>
                                <Contact key={index} name={contact.name} avatar={contact.logo} id={contact.id} tags={contact.tags}></Contact>
                              </div>
                            );
                          } else {
                            return (
                              <Contact key={index} name={contact.name} avatar={contact.logo} id={contact.id} tags={contact.tags}></Contact>
                            );
                          }
                        }
                        return;
                      })}
                    </div>
                  );
                }
                return <div className="news__content">"Aucune actualité"</div>;
              }}
            </Get>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneBook;
