import React from 'react';
import { NavLink } from 'react-router-dom';

function LinkButton({ link, text, lightness }) {
    return (        
        <NavLink className="link-w" to={ link }>
            <div className={ lightness ? "btn btn-lightness" : "btn"}>{ text }</div>
        </NavLink>
    );
}

export default LinkButton;