import React from "react";
import Lottie from "react-lottie";
import * as animationData from "./../utils/loader.json";

function LottieControl(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={80} width={80} />
    </div>
  );
}

export default LottieControl;
