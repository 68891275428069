import React from "react";
import Moment from "react-moment";
import "./Announcement.css";
import CardHeading from "./CardHeading";
import LinkButton from "./LinkButton";

function JobOffer({ user, userId, idOffer, date, description, avatar }) {
  let previewText = "";
  if (description) {
    previewText = description.replace(/(<([^>]+)>)/gi, "");
  }
  return (
    <div className="announcement animate__animated animate__fadeInUp">
      <CardHeading
        userId={userId}
        user={user}
        avatar={avatar}
        date={
          <Moment locale="fr" fromNow>
            {date}
          </Moment>
        }
        text=" a publié une nouvelle offre d'emploi."
      ></CardHeading>
      <div className="announcement__content">{previewText}</div>
      <LinkButton text="Voir l'offre" link={"/offre-d-emploi/" + idOffer} lightness></LinkButton>
    </div>
  );
}

export default JobOffer;
