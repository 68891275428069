import React, { Component } from "react";
import "./AddAnnouncement.css";
import PropTypes from "prop-types";

class AddAnnouncementInput extends Component {
  static defaultProps = {
    name: "text",
    placeholder: "Entrez votre contenu",
    required: true,
  };

  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    charLeft: PropTypes.number,
    charLimit: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = { value: "", charLeft: 280, charLimit: 280 };
  }

  handleChange = ({ target: { value } }) => {
    let left = this.state.charLimit - value.length;

    this.setState((prevState, props) => ({ charLeft: left }));

    if (value.length < this.state.charLimit && this.state.charLeft > 1) {
      this.setState({ value });
    }
  };

  render() {
    const { name, placeholder, required } = this.props;
    const { charLeft } = this.state;
    return (
      <div>
        <textarea
          maxLength="280"
          className="textareaInput"
          name={name}
          onChange={this.handleChange}
          placeholder={placeholder}
          required={required}
          type="text"
          value={this.state.value}
        />
        <div className={charLeft <= 0 ? "small-text input-info primary-text" : "small-text input-info"}>{charLeft} caractères restants</div>
      </div>
    );
  }
}

export default AddAnnouncementInput;
