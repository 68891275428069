import React, { useState, useEffect } from "react";
import Announcement from "./../../Announcement";
import Loader from "../../Loader";
import axios from "axios";

function Announcements({ userId }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (hasMore) {
      setLoading(true);
      let cancel;
      axios
        .get(process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_annonces/", {
          params: {
            _embed: 1,
            author: userId,
            per_page: 6,
            page: pageNumber,
          },
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
        .then(function (response) {
          if (Number(response.headers["x-wp-totalpages"]) === pageNumber) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setData([...data, ...response.data]);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setError(true);
        })
        .then(function () {
          // always executed
        });
      return () => cancel();
    }
  }, [pageNumber]);

  // User bottom page
  useEffect(() => {
    function handleBottom() {
      // si l'utilisateur atteind le bas de la page, on charge la page suivante
      if (document.getElementById("root").getBoundingClientRect().bottom <= window.innerHeight + 50) {
        // on ajoute 50 pour lancer le chargement légèrement avant qu'on atteingne le bas de la page
        if (!loading && hasMore) {
          setPageNumber((v) => v + 1);
        }
      }
    }
    document.addEventListener("scroll", handleBottom);

    return function () {
      document.removeEventListener("scroll", handleBottom);
    };
  }, [loading, hasMore]);

  return (
    <div>
      {data ? (
        <div>
          {data.map((announcement, index) => {
            // extract Avatar Url
            let avatarCompanyUrl = "";
            if (announcement._embedded["author"][0].acf.entreprise_users) {
              avatarCompanyUrl = announcement._embedded["author"][0].acf.entreprise_users.user_avatar;
              avatarCompanyUrl = avatarCompanyUrl.substring(
                avatarCompanyUrl.lastIndexOf('src="') + 5,
                avatarCompanyUrl.lastIndexOf("class=") - 2
              );
            }
            // Si l'annonce est celle d'une entreprise et que qu'user accepte OU l'annonce n'est pas celle d'une entreprise
            if (
              (!announcement._embedded["author"][0].acf.entreprise_users && window.userData.acf.informe_offres_users) ||
              announcement._embedded["author"][0].acf.entreprise_users
            ) {
              return (
                <Announcement
                  id={announcement.id}
                  key={index}
                  user={announcement._embedded["author"][0].name}
                  userId={announcement.author}
                  content={announcement.content.rendered}
                  avatar={announcement._embedded["author"][0].acf.profil_users.url}
                  date={announcement.date}
                  avatarCompany={avatarCompanyUrl}
                  mail={announcement._embedded["author"][0].user_email}
                  image={
                    announcement._embedded["wp:featuredmedia"] &&
                    announcement._embedded["wp:featuredmedia"][0].media_details.sizes["app-size"].source_url
                  }
                  imageFull={
                    announcement._embedded["wp:featuredmedia"] &&
                    announcement._embedded["wp:featuredmedia"][0].media_details.sizes["full"].source_url
                  }
                ></Announcement>
              );
            }
          })}
          {data.length == 0 && !loading && <p className="center-text">Aucune annonce postée</p>}
        </div>
      ) : (
        <div>{error && <div>"Une erreur s'est produite"</div>}</div>
      )}

      {loading && <Loader></Loader>}
    </div>
  );
}

export default Announcements;
