import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Homepage from "./components/pages/homepage/Homepage";
import PhoneBook from "./components/pages/phonebook/PhoneBook";
import Profile from "./components/pages/profile/Profile";
import Settings from "./components/pages/profile/Settings";
import AddAnnouncement from "./components/pages/announcement/AddAnnouncement";
import Menu from "./components/Menu";
import News from "./components/pages/news/NewsPage";
import Event from "./components/pages/event/EventPage";
import Job from "./components/pages/job/JobPage";
import Company from "./components/pages/company/CompanyPage";
import OnBoarding from "./components/pages/onboarding/OnBoarding";
import SwitchProfil from "./components/pages/user/SwitchProfil";
import Search from "./components/pages/search/Search";

function App() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/annuaire" component={PhoneBook} />
          <Route exact path="/mon-profil" component={Profile} />
          <Route exact path="/parametres" component={Settings} />
          <Route exact path="/poster-une-annonce" component={AddAnnouncement} />
          <Route exact path="/actualite/:id" component={News} />
          <Route exact path="/evenement/:id" component={Event} />
          <Route exact path="/offre-d-emploi/:id" component={Job} />
          <Route exact path="/entreprise/:id" component={Company} />
          <Route exact path="/bienvenue" component={OnBoarding} />
          <Route exact path="/profil/:id" component={SwitchProfil} />
          <Route exact path="/rechercher" component={Search} />
        </Switch>
        <Menu />
      </div>
    </BrowserRouter>
  );
}

export default App;
