import React, { useState } from "react";
import { Get } from "react-axios";
import "./Login.css";
import MailInput from "./MailInput";
import PasswordInput from "./PasswordInput";
import Loader from "../../Loader";

function Login(props) {
  // Remove Menu
  document.addEventListener("DOMContentLoaded", () => {
    if (document.getElementById("menu")) {
      document.getElementById("menu").style.display = "none";
    }
  });

  const background = window.location.origin + "/images/login-image.png";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isReady, setIsReady] = useState(false);
  return (
    <div className="login">
      <div className="login__header-image" style={{ backgroundImage: `url(${background})` }}></div>
      <div className="login__wrapper">
        <div className="login__input-container">
          <form
            method="get"
            onSubmit={(event) => {
              event.preventDefault();
              setUsername(event.target[0].value);
              setPassword(event.target[1].value);
              setIsReady(true);
            }}
          >
            <div className="login__title bold-text">Se connecter</div>
            <div className="login__input-wrapper">
              <div className="login__label"></div>
              <div className="login__input">
                <MailInput></MailInput>
              </div>
            </div>
            <div className="login__input-wrapper">
              <div className="login__input">
                <PasswordInput></PasswordInput>
              </div>
              <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_API_URL + "/reinitialiser-mot-de-passe/"}>
                <div className="login__password-forget small-text underline-text">Mot de passe oublié ?</div>
              </a>
            </div>
            <Get
              url={process.env.REACT_APP_API_URL + "/api/user/generate_auth_cookie/"}
              params={{ username: username, password: password, insecure: "cool" }} // expiration = 2 mois
              isReady={isReady}
            >
              {(error, response, isLoading, makeRequest, axios) => {
                if (error) {
                  return (
                    <div>
                      Something bad happened: {error.message}{" "}
                      <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
                    </div>
                  );
                } else if (isLoading) {
                  return <Loader></Loader>;
                } else if (response !== null) {
                  if (response.data.status === "error") {
                    return <div className="login__error-message">Adresse e-mail ou mot de passe incorrect</div>;
                  } else if (response.data.status === "ok") {
                    // Authentification process
                    document.cookie = "logged=" + response.data.cookie + "; max-age=518400000"; // expiration lointaine, très lointaine
                    window.location = window.location.origin + "/bienvenue";
                  }
                }
                return "";
              }}
            </Get>
            <button type="submit" className="btn">
              Se connecter
            </button>
          </form>
          <div className="login__no-account">
            Vous n'avez pas de compte ?
            <a target="_blank" href={process.env.REACT_APP_API_URL + "/creation-compte/"}>
              <span className="primary-text"> Créez le votre</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
