import React from "react";
import PageTitle from "./PageTitle";
import LeftHeaderButton from "./LeftHeaderButton";
import RightHeaderButton from "./RightHeaderButton";

import "./Header.css";

const Header = ({ pageTitle, linkLeftButton, linkRightButton }) => {
  return (
    <div className="header">
      <LeftHeaderButton linkLeftButton={linkLeftButton}></LeftHeaderButton>
      <PageTitle className="header__title" pageTitle={pageTitle}></PageTitle>
      <RightHeaderButton link={linkRightButton}></RightHeaderButton>
    </div>
  );
};

export default Header;
