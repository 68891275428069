import React from "react";
import Header from "../../Header";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Moment from "react-moment";
import { Get } from "react-axios";
import Loader from "../../Loader";
import "./EventPage.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function EventPage(props) {
  ScrollToTop();
  const eventId = window.location.pathname.replace("/evenement/", "");
  return (
    <div className="event-page">
      <Header pageTitle="Événement"></Header>
      <Get url={process.env.REACT_APP_API_URL + "/wp-json/wp/v2/arch_mag/" + eventId} params={{ _embed: 1 }}>
        {(error, response, isLoading, makeRequest, axios) => {
          if (error) {
            return (
              <div className="event-page__content">
                Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button>
              </div>
            );
          } else if (isLoading) {
            return (
              <div className="event-page__content">
                <Loader></Loader>
              </div>
            );
          } else if (response !== null) {
            if (response.data.acf.date_event && response.data.acf.publier_app) {
              return (
                <div>
                  <div className="event-page__banner img-cover">
                    <picture>
                      <source
                        media="(min-width: 480px)"
                        srcSet={response.data._embedded["wp:featuredmedia"][0].media_details.sizes["full"].source_url}
                      ></source>
                      <img src={response.data._embedded["wp:featuredmedia"][0].media_details.sizes["app-size"].source_url} alt="news"></img>
                    </picture>
                  </div>
                  <div className="event-page__avatar-over-banner-wrapper">
                    <div className="event-page__avatar-over-banner">
                      <img src={response.data._embedded.author[0].acf.profil_users.url} alt="" />
                    </div>
                  </div>
                  <div className="event-page__content-wrapper">
                    <div className="event-page__content">
                      <div className="event-page__info-section">
                        <div className="event-page__icon">
                          <img src={window.location.origin + "/images/assets/event-icon.png"} alt=""></img>
                        </div>
                        <div className="event-page__event-infos">
                          <div className="event-page__event-date primary-text">
                            {response.data.acf.date_event} à {response.data.acf.heure_event.replace(":", "h")}
                          </div>
                          <div className="event-page__event-location small-text">{response.data.acf.lieu_event}</div>
                        </div>
                      </div>
                      <div className="event-page__post-date">
                        <p className="small-text small-text--blue">
                          {" "}
                          Publié le{" "}
                          <Moment locale="fr" format="D MMMM YYYY">
                            {response.data.data}
                          </Moment>
                          , par{" "}
                          {response.data._embedded.author[0].id !== 87 ?
                            <NavLink to={"/profil/" + response.data._embedded.author[0].id}>
                              <span className="link bold-text secondary-text">{response.data._embedded.author[0].name}</span>
                            </NavLink>
                          :                             
                              <span className="link bold-text secondary-text">{response.data._embedded.author[0].name}</span>
                          }
                        </p>
                      </div>
                      <div className="event-page__title">
                        <h1>{response.data.title.rendered}</h1>
                      </div>
                      <div className="event-page__text" dangerouslySetInnerHTML={{ __html: response.data.acf.contenu }}></div>
                    </div>
                  </div>
                </div>
              );
            }
          }
          return <div className="event-page__content">"Aucun événement trouvé"</div>;
        }}
      </Get>
    </div>
  );
}

export default EventPage;
