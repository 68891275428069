import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import Login from "./components/pages/login/Login";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "moment/locale/fr";
import axios from "axios";
import Cookies from "js-cookie";

if (Cookies.get("logged")) {
  let cookie = Cookies.get("logged");
  axios
    .get(process.env.REACT_APP_API_URL + "/api/auth/get_currentuserinfo/", {
      params: {
        cookie: cookie,
        insecure: "cool",
      },
    })
    .then(function (response) {
      if (response.data.status === "ok") {
        axios
          .get(process.env.REACT_APP_API_URL + "/wp-json/wp/v2/users/" + response.data.user.id, {
            headers: { Authorization: "Basic YXBpLXNlY3VyZUBzdHVkaW8tZXZvbC5mcjpsb2xjb2RlMDE=" },
          })
          .then(function (resp) {
            window.userData = resp.data;
            ReactDOM.render(
              <React.StrictMode>
                <App />
              </React.StrictMode>,
              document.getElementById("root")
            );
          });
      } else {
        ReactDOM.render(
          <React.StrictMode>
            <BrowserRouter>
              <div className="wrapper">
                <Login />
              </div>
            </BrowserRouter>
          </React.StrictMode>,
          document.getElementById("root")
        );
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {
      // always executed
    });
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <div className="wrapper">
          <Login />
        </div>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
