import React from "react";
import { NavLink } from "react-router-dom";
import "./CardHeading.css";

function CardHeading({ user, avatar, text, companyAvatarOver, date, userId }) {
  return (
    <div className="card-heading__heading">
      <NavLink className="link card-heading__user" to={userId !== 87 ? "/profil/" + userId : "/#"}>
        <div className="card-heading__avatar" style={{ backgroundImage: `url(${avatar})` }}>
          {companyAvatarOver ? (
            <div className="card-heading__avatar-compagny">
              <img src={companyAvatarOver} alt=""></img>
            </div>
          ) : (
            ""
          )}
        </div>
      </NavLink>
      <div className="card-heading__text-heading">
        <div>
          {userId !== 87 ?
            <>
              <NavLink className="link card-heading__user" to={"/profil/" + userId}>
                {user}
              </NavLink>{" "}          
            </>
            :
            <>
              <span className="link card-heading__user">
                {user}
              </span>{" "}          
            </>
          }
          {text}
        </div>
        <div className="card-heading__post-date small-text">{date}</div>
      </div>
    </div>
  );
}

export default CardHeading;
